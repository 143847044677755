import React from 'react';

export default function Mac() {
	return (
		<div>
			<h1>Experiences with Mithrandir</h1>
			<p>
				This writeup is a bit overdue. I got a MacBook Pro with the M1 chip
				(Mithrandir) back in Februrary 2022, and I figured I'd jot down some of my
				thoughts and experiences.
				<br/>
				<br/>
				I had originally intended to put Arch Linux on Mithrandir, but that's not
				much of an option with this new MacBook. It's really pretty annoying, but
				I'm running the default operating system on Mithrandir.
				<br/>
				<br/>
				That being said, some of my experiences and discomfort stem from having
				exclusively used PCs and my inexperience with this computer and its native
				software.
			</p>

			<h2>First Impressions</h2>
			<h3>Physical components</h3>
			<p>
				Physically, the first things I noticed were the lack of the forward delete
				button (typically placed somewhere above the backspace key) and the ease of
				use of the keyboard. The typing experience is silent and the feel of the
				keyboard is quite nice. The keys are positioned a bit differently to where
				the left <em>Alt</em> key (there's no equivalent, but I use
				the <em>Option</em> key) is uncomfortable to reach. I've had to get used to the default
				keybindings using <em>Command</em> instead of <em>Control</em>.
			</p>
			<p>
				The battery performance is excellent. It's one of the things I love the most
				about this computer. It charges quite quickly and lasts for far longer than
				any of my other computers. I've never really noticed it get hot, and the fan
				is silent. To be fair, I've never done anything particularly CPU-intensive 
				on this machine, so that still remains to be tested. The input and output audio
				systems are also excellent.
			</p>

			<h3>Software</h3>
			<p>
				As for the software, the lack of customizability was the first thing that struck me,
				and the number of assumptions made over what the user would like. I really enjoy learning
				more about my computer, and I'd argue that the easiest way to learn more is through trial-and-error
				customization. Having so many things black-boxed felt quite inhibiting.
			</p>
			<p>
				For example, running <span className="monospace">ps aux</span> {/*TODO*/}.
				There doesn't seem to be much clear documentation regarding default behavior or how to modify it.
				Most forums discuss modifying things in System Preferences, which doesn't offer me nearly as much
				customizability as I would like.
			</p>
			<p>
			</p>
			<h2>Necessary Modifications and Workarounds</h2>

			<h3>Command Line Access</h3>
			<p>
				At the present, I'm using Kitty as my terminal. Mapping the globe-looking
				key on the bottom left of the keyboard to <em>Control</em> is an absolute must,
				as is mapping <em>Option</em> to <em>Alt</em> in order to use <em>Alt</em> + <em>.</em> in Bash.
				The default shell is Zsh, but I prefer Bash, so I had to change that as well.
				This results in a message being printed to terminal on each session, which can
				be disabled by adding the
				line <span className="monospace">export BASH_SILENCE_DEPRECATION_WARNING=1</span> to
				your <span className="monospace">.bashrc</span>.
			</p>

			<h3>Unresolved Issues</h3>
			<p>
				Here are some issues I haven't been able to fix just yet.
				I'll keep this post updated as I learn more. If you have a solution
				for one of the following issues, please let me know!
				<ul>
					<li>
						Sound: I would like to be able to control the output volume on a per app basis,
						just as <span className="monospace">pulse</span> lets me do.
					</li>
					<li>
						Killing a process: X-ing out a screen doesn't necessarily <em>kill</em> a process. For example,
						X-ing out RStudio does kill the program, but in the Messages app, I believe it's just
						sending it the process to the background, where it continues to run (there's still a white dot
						below the app on the Dock, and I'm not still sure what exactly that signifies). The easiest
						way to properly kill is by using <em>Command</em> + <em>q</em>. I want a way
						to have X-ing out bound to whatever action is being performed
						by <em>Command</em> + <em>q</em> (SIGTERM? SIGKILL?).
					</li>
					<li>
						Restarting: similar to the issue with killing processes, I'm not sure why some processes
						that are running prior to reboot are launched after starting. I wish I could modify
						which processes are relaunched post reboot, and which processes are started on booting.
					</li>
					<li>
						Colorizing output: the standard Bash sequences don't work for some reason.
						{/*TODO*/}
					</li>
				</ul>
			</p>

		</div>
	)
}
