import React from 'react';
import { Link } from 'react-router-dom';

export default function Error() {
	return (
		<div>
			<h1>404: Page not found 😭 </h1>
			<p>
				Oh noooooo! It looks like this page doesn't exist.
				If you think this page was supposed to exist,
				though, please <Link to="/contact">let me know</Link>.
				<br/>
				<br/>
				But for now, click something in the navbar above to head back!
			</p>
		</div>
	);
}
