import React from 'react';

export default function Scroll () {
	return (
		<div>
			<h1>Natural Scrolling on i3</h1>

			<h2>The Problem</h2>
			<p>
				i3 seems to have traditional scrolling, where your scrolling motion
				matches that of the scrollbar, as the default, but I wanted natural
				scrolling where your scrolling motion matches the motion of the page.
			</p>
			<h2>Stuff that didn't work</h2>
			<p>
				Based on an <a href="https://wiki.archlinux.org/index.php/Libinput#Via_xinput">ArchWikis
				entry</a>,
				I decided to use <span className="monospace">xinput</span>.
				Since I didn't have  <span className="monospace">libinput</span> installed,
				I decided to run
			</p>
			<div className="realcode">
				xinput list
			</div>
			<p>
				At any rate, I tried the solution recommended, which did nothing.
				The device in question was the "PS/2 Synaptics TouchPad"; this is really
				important, keep this in mind for later.
			</p>
			<p>
				The next thing I tried was screwing with some config files.
				I was told to add a file
				to the <span className="monospace">/etc/X11/xorg.conf.d</span> directory,
				but that directory didn't exit.

				I therefore tried editing
				the <span className="monospace">/usr/share/X11/xorg.conf.d</span> directory,
				since it at least contained
				the <span className="monospace">xorg.conf.d</span> directory.
				I made a file called
				called <span className="monospace">70-libinput.conf</span> and
				filled it with the following:
			</p>
			<div className="realcode">
				Section "InputClass" <br />
					&emsp;	&emsp; Identifier "libinput touchpad catchall"<br />
					&emsp;	&emsp; MatchIsTouchpad "on"<br />
					&emsp;	&emsp; MatchDevicePath "/dev/input/event*"<br />
					&emsp;	&emsp; Option "NaturalScrolling" "false"<br />
					&emsp;	&emsp; Option "Tapping" "on"<br />
					&emsp;	&emsp; Driver "libinput"<br />
				EndSection
			</div>
			<p>
				The "tapping" bit is since I don't like pressing my trackpad
				all the way for it to register as a click.
			</p>
			<p>
				After a bit more searching, I found that you're supposed
				to copy the files to <span className="monospace">/etc/X11/xorg.conf.d</span>.
				I tried that, and renamed the file, different things,
				such as <span className="monospace">30-touchpad</span> and <span className="monospace">40-libinput</span>,
				and playing with different numbers, which refer to the priority of the file.
				These didn't work either.
			</p>
			<p>
				Evidently, I can't read.
				The ArchWiki's linked in the beginning suggested running
			</p>
			<div className="realcode">
				libinput list-devices
			</div>
			<p>
				However, I didn't have <span className="monospace">libinput</span> installed.
				There wasn't a package with that exact name, so
				I decided to install <span className="monospace">libinput-tools</span>. This
				seemed to do the trick.
			</p>
			<p>
				The <span className="monospace">list-devices</span> bit just displays the info
				a bit differently; it's not that big of a deal breaker. However,
				this time, when I tried the <span className="monospace">xinput</span> command
				from earlier, it now showed another touchpad, "SYNA2393:00 06CB:7A13 Touchpad".
				I repeated the steps from earlier using this device instead, and the steps from
				the ArchWiki worked.
			</p>
			<p>
				Since I was satisfied with the changes I'd made, I need to write
				them to file. I needed a <span className="monospace">.xinitrc</span> file,
				so I copied it over
				from <span className="monospace">/etc/X11/xinit/xinitrc</span> to
				my home directory. To this file, I added the lines
			</p>

			<div className="realcode">
				xinput set-prop "SYNA2393:00 06CB:7A13 Touchpad" "libinput Tapping Enabled" 1 <br />
				xinput set-prop "SYNA2393:00 06CB:7A13 Touchpad" "libinput Natural Scrolling Enabled" 1
			</div>
	
			<p>
				Again, I wanted both tapping and natural scrolling.
				For the temporary testing, the device numbers work fine, but
				for something to be loaded for every session, it's recommended
				to use the actual names, since the numbers can change.
				This <span className="monospace">/etc/X11/xinit/xinitrc</span> solution did not work for me,
				since on reboot, it only did the first command.
			</p>
			<h2>The Solution</h2>
			<p>
				The final solution was adding the following lines to my i3 config:
			</p>

			<div className="realcode">
				exec --no-startup-id xinput set-prop "SYNA2393:00 06CB:7A13 Touchpad"
					"libinput Tapping Enabled" 1 & <br/>
				exec --no-startup-id xinput set-prop "SYNA2393:00 06CB:7A13 Touchpad"
					"libinput Natural Scrolling Enabled" 1
			</div>
			<p>
				Update for Arch: make sure <span className="monospace">xinput</span> is installed!
				If not, install <span className="monospace">xorg-xinput</span>, and you'll be golden.
			</p>
		</div>
	)
}
