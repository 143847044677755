import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Set Fire to the Rain_ by Adele

La agenda de hoy:

- Terminar la unidad 2 (lecciones E y F)
- Repasar la unidad 2 con unas actividades del libro

*******************************************************************************

## Repaso


 | _Palabra_ | Word |
 | ---- | ----- |
|_cuaderno_ | a notebook |
 |_libro_ | a book|
_borrador_ | an eraser
_computadora_ | a computer
_escritorio_ | a desk
_papel_ | paper
_una hoja de papel_ | a sheet of paper
_regla_ | a ruler
_diccionario_ | a dictionary
_grapadora_ | a stapler
_pluma_ | a pen
_silla_ | a chair
_lápiz_ | a pencil
_mesa_ | a table

| Día | Day |
| --- | ---- |
| domingo | Sunday |
| lunes | Monday |
| martes | Tuesday |
| miércoles | Wednesday (wensday)|
| jueves | Thursday |
| viernes | Friday |
| sábado | Saturday |

- El lunes, voy al trabajo = On Monday, I go to work
- La clase es el martes = The class is on Tuesday
- Tenemos clase los jueves = On Thursdays, we have class 

## Las fechas
- _Hoy es el 19 de septiembre_ = Today is September 19<sup>th</sup>
- _4 de julio_ = July 4<sup>th</sup>
- _el 25 de diciembre_ = December 25<sup>th</sup>
- My birthday is on ...= _Mi cumpleaños es el ..._
- My mother's/father's/sister's/brother's/son's/daughter's birthday is on ...

## Los números ordinales
| Ordinal number | Forma corta | Número ordinal|
| --- | --- |  --- |
| first |1<sup>st</sup>  | primero|
second |2<sup>nd</sup>| segundo
 third |3<sup>rd</sup> | tercero
fourth |4<sup>th</sup> | cuarto
fifth |5<sup>th</sup> | quinto
sixth |6<sup>th</sup> | sexto
seventh |7<sup>th</sup> | séptimo
eighth |8<sup>th</sup> | octavo
ninth |9<sup>th</sup> | noveno
tenth |10<sup>th</sup> | décimo
eleventh | 11<sup>th</sup>  | undécimo |  
twelfth | 12<sup>th</sup>  | duodécimo |  
thirteenth | 13<sup>th</sup>  | decimotercero |  
fourteenth | 14<sup>th</sup>  | decimocuarto |  
fifteenth | 15<sup>th</sup>  | decimoquinto |  
sixteenth | 16<sup>th</sup>  | decimosexto |  
seventeenth | 17<sup>th</sup>  | decimoséptimo |  
eighteenth | 18<sup>th</sup>  | decimoctavo |  
ninteenth | 19<sup>th</sup>  | decimonoveno |  
twentieth | 20<sup>th</sup>  | vigésimo | 
twenty-first | 21<sup>st</sup> | vigésimo primero | 
twenty-second | 22<sup>nd</sup> | vigésimo segundo | 
twenty-third | 23<sup>rd</sup> | vigésimo tercero | 
twenty-fourth | 24<sup>th</sup> | vigésimo cuarto | 
twenty-fifth | 25<sup>th</sup> | vigésimo quinto | 
twenty-sixth | 26<sup>th</sup> | vigésimo sexto | 
twenty-seventh | 27<sup>th</sup> | vigésimo séptimo | 
twenty-eighth | 28<sup>th</sup> | vigésimo octavo | 
twenty-ninth | 29<sup>th</sup> | vigésimo noveno| 
thirtieth | 30<sup>th</sup> | trigésimo | 
thirty-first | 31<sup>st</sup> | trigésimo primero | 
*******************************************************************************

`

export default function Week02() {
	return (
		<div>
			<h1>Week 3 Lecture Notes (9/19)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
				<ul>
					<li><a href="https://www.ingles.com/guia/como-dar-la-fecha-en-ingles">La fecha</a></li>
				</ul>
		</div>
	)
}
