import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Arcade_ by Duncan Laurence

La agenda de hoy:

- Repasar las primeras secciones de Unidad 4
- Hacer actividades en secciones C y D de Unidad 4

*******************************************************************************
## Repaso

|_Palabra_| Word |
|:-----|:-----|
|consultorio| doctor’s office|
|medicina| medicine|
|enfermero| nurse|
|paciente| patient|
|doctor| doctor|

|_Partes del cuerpo_| Parts of the Body |
|:-----|:-----|
|mano| hand|
|estómago| stomach|
|pie| foot|
|pierna| leg|
|brazo| arm|
|cabeza| head|
|ojo | eye |
|garganta | throat |

| Singular | Plural |
| ---- | ---- |
| foot (pie) | feet (pies )| 
| tooth (diente) | teeth (dientes) |


## What hurts? = ¿Qué te duele?

- Me duele el brazo = My arm hurts. (singular)
- Me duelen los brazos = My arms hurt. (plural)
- Me duele la pierna = My leg hurts.
- Me duelen las piernas = My legs hurt.
- Me duele el pie = My foot hurts.
- Me duelen los pies = My feet hurt.


## Unas enfermedades
| English | Español |
|:-----| :-----|
|stomachache |_dolor de estómago_|
|ache (eik)| _dolor_|
|headache | _dolor de cabeza_|
|toothache | _dolor de muelas_|
|sore throat | _dolor de garganta_|
|fever | _fiebre_|
|cold | _resfriado_|

|Pronoun | Conjugation|
|:------| :-----|
| I | have|
|You | have|
|She/He | has|
|We | have|
|They | have|

<pronombre personal/nombre de la persona> + <conjugación del verbo _to have_> + <a/an> + <la enfermedad/el síntoma>

| Frases | Traducción |
| --- | --- |
| Tengo una fiebre |  I have a fever |
| Manuel tiene dolor de garganta. | Manuel has a sore throat |
| David tiene dolor de estómago. | David has a stomachache | 
| Ana tiene dolor de cabeza | Ana has a headache | 
| Tienes dolor de muelas | You have a toothache | 
| Ella está resfriada | She has a cold | 

### Hot and Cold

- I have a cold (to have) = Estoy resfriada
- I am cold (to be) = Tengo frío 
- I am hot = Tengo calor
- He is cold = Él tiene frío
- He has a cold = Él está resfriado
- I'm cold = Tengo frío
- She's hot = Ella tiene calor

|Pronoun| Conjugation| Contraction|
|:-----|:------|:-----|
|I| am |I’m|
|You|are|You’re|
|She/He/It|is|She’s/ He’s|
|We|are|We’re|
|They|are|They’re|
*******************************************************************************
- Me duele la cabeza = My head hurts
- Tengo dolor de cabeza = I have a headache
- David tiene dolor de estómago = David has a stomachache
- A David le duele el estómago = David's stomach hurts
- A él le duele el estómago = His stomach hurts
- Manuel tiene dolor de garganta. = Manuel has a sore throat
- A Manuel le duele la garganta = Manuel's throat hurts.
- A Nick le duelen los pies = Nick's feet hurt.
*******************************************************************************

## Possession

- agregar 's o solamente '
- La pluma de Raquel = Raquel's pen
- La grapadora de Miguel = Miguel's stapler
- La cartera de Olivia = Olivia's handbag
- L casa de los perros = the dogs' house
- las quejas de los clientes = customers' complaints
*******************************************************************************

## Una lista más completa

|English | Español|
|:----|:-----|
|body|cuerpo|
|foot|pie|
|feet |pies|
|leg|pierna|
|toe|dedo de pie|
|hand |mano|
|arm|brazo|
|finger|dedo|
|tooth|diente|
|teeth|dientes|
|neck|cuello|
|nose|nariz|
|eyes|ojos|
|mouth|boca|
|ears|orejas|
|head|cabeza|
|hair|cabello|
|tongue|lengua|
|eyelashes|pestañas|
|eyebrows|cejas|
|eyelid|párpado|
|face|cara|
|forehead|la frente|
|elbow|codo|
|shoulders|hombros|
|knees|rodillas|
|nails|uñas|
|ankle|tobillo|
|heel|talón|
|waist|cintura|
|chest|pecho|
|heart|corazón|
|stomach|estómago|
|throat|garganta|
|wrist|muñeca|
|jaw|mandíbula|
|back|espalda|
|thumb|pulgar|
|index finger |índice|
|middle finger |medio|
|ring finger |anular|
|little finger/pinky |meñique|
*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 5 Lecture Notes (10/3)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
				<ul>
					<li><a href="https://quizlet.com/124522888/las-partes-del-cuerpo-body-parts-flash-cards/">Las partes del cuerpo</a></li>
					<li><a href="https://www.inglessencillo.com/el-plural">Formar el plural</a></li>
					<li><a href="https://www.ispeakspokespoken.com/es/posesion-ingles/">Posesión en inglés</a></li>
				</ul>
		</div>
	)
}
