import React from 'react';

export default function Audio() {
	return (
		<div>
			<h1>Audio Startup with PulseAudio</h1>	
			<h2>The Problem</h2>
			<p>
				After I moved to i3, I've interacted more with PulseAudio to change my
				audio settings. I've had a couple of issues: 

			</p>
			<ul>
				<li>
					Polybar doesn't immediately show the audio bar after i3 launches.
					I've had to restart i3 a few times for it to launch.
				</li>
				<li>
					After attempting to restart, it still won't launch
				</li>
			</ul>

			<h2>Stuff that didn't work</h2>
			<p>
				The way I used to handle these issues (whenever they'd occur,
				since PulseAudio worked inconsistently) for the longest time
				was to kill the process and relaunch it, but this was really
				annoying, since I'd have to do that every time I logged in.
				In order to port this to i3, I tried to use some combination
				of killing and starting the process,
				but that would make it so that PulseAudio worked on every other launch,
				which was a pretty jank solution. I had something like the following:
			</p>
			<div className = "realcode">
				exec_always --no-startup-id pulseaudio --start <br/>
				exec_always --no-startup-id pulseaudio --kill <br/>
				exec_always --no-startup-id pulseaudio --start <br/>
			</div>
			<p>
				For a good amount of time, my workaround was to try to start it,
				if it was already running, kill the instance, and try to restart it.
				I also tried stopping the service and restarting it.
				That was pretty annoying, however, since this wasn't being run from
				the getgo.
			</p>
			<h2>The Solution</h2>
			<p>
				The easiest way is to just enable the service from your i3 config
				using the following line
			</p>
			<div className = "realcode">
				exec_always systemctl --user enable pulseaudio --now
			</div>
			<p>
				This resolves both the issues previously mentioned.
			</p>
				

		</div>
	)
}
