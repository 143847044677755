export default function ESL() {
	return (
		<div>
			<h1>ESL Class Workflow</h1>
			<p>
				I've taught a virtual ESL className at El Buen Samaritano for several semesters, and
				I publish my lecture notes after each className. I like formatting the notes to give
				concepts a little more context especially since classNamees aren't recorded.
				I've been trying to find a good workflow for typing notes in className to reduce
				the amount of time I spend after className reformatting/prettying things.
			</p>
			<h2>Former workflows</h2>
			<p>
				I used to treat my className notes more or less like a live coding session, where
				I'd type out examples in Vim. I'd later copy the the examples from className and
				type out a pretty document in LaTeX. This process easily took me 5+ hours after each
				className, especially including things like tables. I then tried using Vim on a Mac, which
				didn't really work since pressing to get diacritics doesn't function within Vim, but it does
				work within the Notes app, so I used the Notes app for a good stretch of time.
				I'd then copy and paste it all and convert it to markdown, which I'd then publish on
				the course website. This was less tiring, since markdown's considerably easier to write in, 
				but I didn't enjoy the diacritics and lack of Vim situation. 
			</p>

			<p>
				With that experience, here's a summary of my preferences:
			</p>
			<ul>
				<li>
					I'm really used to Linux keybindings for accent marks, 
					and I don't really intend to learn Mac keybindings.
				</li>
				<li>
					Zoom's really weird on Linux, especially when I'm screensharing.
					<s>It sometimes freezes a part of my screen, and I have to kill Zoom to get my screen back.</s>
					I'm thinking this issue occurs when I'm sharing a desktop - it's quite possible that Zoom
					doesn't know how to handle i3 workspaces, because I don't remember having this issue when I
					used GNOME/GNOME-based desktop environments.
					With my Mac, on the other hand, I don't experience this issue at all.
				</li>
				<li>
					I'm fastest when I'm typing in Vim. I love using tab-complete,
					and I'm just too accustomed to my settings and keybindings to use other editors.
					I've also tried using the Notes app in Mac, and I got frustrated too easily.
				</li>
			</ul>

			<h2>Current workflow</h2>
			<p>
				Since the sort of editing I do for my ESL className is pretty similar to what I do for notetaking,
				I've decided to use the same workflow for both!
			</p>
			<p>
				I absolutely love RMarkdown for a lot of my casual editing that requires some amount of formatting.
				I've grown to love being able to quickly type rich text, code snippets (with syntax highlighting!) and
				math and have it render prettily. My biggest problems with using RStudio for notetaking (not actual
				data analysis/visualization) were that I couldn't use my Vim configuration and that I was using
				a bloated application to perform a pretty simple task. 
			</p>
			<p>
				My workaround for this has been to call <span className="monospace">knitr</span> outside of RStudio.
				You'll probably need to have R installed, and maybe a package or two
				like <span className="monospace">rmarkdown</span> within R
				(I don't recall exactly which packages were required). I do have RStudio installed independently,
				so if you want to use this workflow <em>without</em> having RStudio, you might
				also have to install packages like <span className="monospace">pandoc</span> through your package manager.
			</p>
			<p>
				The following files are required for my current workflow:
			</p>
			<ul>
				<li>
					<span className="monospace">makefile</span>, containing the lines
					<div className="realcode">
						RMDFILE=notes<br/>
						<br/>
						html:<br/>
						R -e "rmarkdown::render('$(RMDFILE).Rmd')"<br/>
					</div>
				</li>
				<li>
					<span className="monospace">notes.Rmd</span>, containing the lecture notes.
					I like using a slightly prettier sans-serif font (Computer Modern)
					and I prefer the PDF rendering to the HTML rendering, so the header contains
					the following lines:
					<div className="realcode">
						---<br/>
						title: "title placeholder"<br/>
						output: pdf_document<br/>
						---<br/>
						\fontfamily&#123;lmss{'}'}<br/>
						\fontsize{'{'}14{'}'}{'{'}16{'}'}<br/>
						\selectfont
					</div>
				</li>
			</ul>
			<p>
				When I teach, I have a terminal split three ways: one containing the Vim session where I'm editing
				the <span className="monospace">notes.Rmd</span> file, one to open the rendered PDF, and one
				where I run
			</p>
			<div className="realcode"> ls notes.Rmd | entr -cs "make" </div>
			<p>
				This way, when I save my notes file, the RMarkdown file is knit.
			</p>
			<p>
				Within Zoom, I share the window containing the PDF or a browser window showing the textbook - 
				not the whole desktop, which avoids the weird issues I mentioned earlier. That's pretty much it!
				The only thing that I don't like is that my students just hear me type for a few seconds, and
				they see the output only after I've saved the document. Previously, I'd just share my editor,
				so they'd see as I typed, but with this workflow, I don't really see a reason for them to see
				the Vim session. I'll keep this document updated with any changes to this workflow!
			</p>
			<h2>Update as of 9/14/23</h2>
			<p>
				My workflow is fairly unchanged for notetaking, but I've changed it a bit for my ESL className.
				Since my course webpage just has a plugin that translates markdown to JSX, I've started
				typing directly into the file that would become my lecture notes, instead of typing into
				an Rmd file. This cuts out the middle man.
				<br/>
				<br/>
				The biggest downside to this setup is that I can't use Vim's commenting features to comment
				out markdown code, since it thinks the file is JSX. My workaround is to have a separate
				tab in Vim that has its filetype set to markdown, and keep any pretyped notes in there,
				and copy them in when I'm ready to talk about that topic.
			</p>

		</div>
	)
}
