import React from 'react';
import { Link } from 'react-router-dom';

import { 
	FaGithub, 
	// FaGitlab,
	FaLinkedin,
	FaDiscord,
	FaEnvelope,
	FaOrcid
} from 'react-icons/fa';
import { TbBrandMatrix } from "react-icons/tb";

export default function Home() {
	return (
		<div className="wstyle">

			<h1> Krithika Ravishankar</h1>
				{/* Hey there! I'm Krithika!{' '} */}
				
				{/* I graduated from <span style={{color: "#bf5700"}}>UT Austin</span> ('22) with a */}
				{/* bachelor's in computer science and a certificate in business Spanish. */}
				{/* I also teach an <Link to="/teaching">ESL course</Link> at El Buen Samaritano. */}

				{/* <ul> */}
				{/* 	<li className="funBullet"> */}
				{/* 		I really love ricing and customizing my setups; */}
				{/* 		check out my <Link to="/blog">blog</Link> for some of my experiences and thoughts! */}
				{/* 	</li> */}
				{/* 	<li className="funBullet"> */}
				{/* 		I also enjoy <Link to="/books">reading</Link>, doodling/painting, and solving crosswords! */}
				{/* 	</li> */}
				{/* 	<li className="funBullet">I have the cutest uromastyx in the world, Shakira (pictured below)!</li> */}
				{/* </ul> */}
				<div className="flex-container">
					<div>
						Hey there! I'm Krithika!{' '}
						I'm a data analyst at <span style={{color: "#f34d3e"}}>Cardinal
					  	Health</span>  and I'm pursuing my master's in computer
					  	science at <span style={{color: "#bf5700"}}>UT Austin</span>. 
			    		I graduated from UT (class of '22) with a
						bachelor's in computer science and a certificate in business Spanish.
						I also teach an <Link to="/teaching">ESL course</Link> at El Buen Samaritano.
						<ul>
							<li className="funBullet">
								I really love ricing and customizing my setups;
								check out my <Link to="/blog">blog</Link> for some of my experiences and thoughts!
							</li>
							<li className="funBullet">
								I also enjoy <Link to="/books">reading</Link>, doodling/painting, and solving crosswords!
							</li>
							<li className="funBullet">I have the cutest uromastyx in the world, Shakira (pictured below)!</li>
						</ul>
				  	</div>
				<div>
			
				Wanna grab a bagel and coffee with me? Here's how to reach me!
				<ul>
					<li className="funBullet">
						<FaEnvelope/>
						{' '}krithika (at) krithravi (dot) com
					</li>
					<li className="funBullet">
						<FaGithub/>
						<a href="https://www.github.com/krithravi"> {' '}krithravi</a>
					</li>
					<li className="funBullet">
						<FaLinkedin/>
						<a href="https://linkedin.com/in/krithravi">{' '}krithravi</a>
					</li>
					<li className="funBullet">
						<TbBrandMatrix/>
						{' '}krithravi:matrix.org
					</li>
					<li className="funBullet">
						<FaDiscord/>
						{' '}krithravi#2664
					</li>
					<li className="funBullet">
						<FaOrcid/>
						<a href="https://orcid.org/0000-0003-4910-0961">
							{' '}0000-0003-4910-0961
						</a>
					</li>
					
				</ul>

				  </div>
				</div>

				<img src="/lizard.jpeg" alt="my small friend" width = "70%" className="center" style={{borderRadius: "20px"}}/>
		</div>
	);
}
