import React from 'react';
import { Link } from 'react-router-dom';

export default function Preferences() {
	let ff_theme="https://color.firefox.com/?theme=XQAAAAKfAQAAAAAAAABBqYhm849SCia9U4KEGccwS-xMDPrwkxcuYG038J2X3KRG99k3KhJD7Ugi5-VItXMPfSSwpQXtbjSSCiiUiJW6wmOKznLHyRg2kQOyGPIOixZf1DHpLgq5XKBIABaw3HtSoqlEyLV4nNZIg7cO56qxuYlmbqaRssxqMDdC7laoMVZUNMcY_zbT7tlwiPlERv5f70gmJDDHxhWFLihH46_77HGYWAhaetV75csbW3KQdYswinff1xvkN2SM8fl6gLudDTCbu5VZrLqNON_gu0pukpBselGXVxPrAe7wqw5Kx9JRrsgRLejtp6AS-G90c43WVGcCQDExGJxgzDvZ3aCD8N2Mp1M0BlMGVkz_4Uz_stFPAA"

	return (
		<div>
			<h1>My Linux Setup</h1>

			<p>
				Yep, this is basically a checklist to get close to my setup/rice on Faramir.
			</p>
			<h2>Things to install</h2>
			<p>
				These are ranked roughly in the order I need them.
			</p>
			<ul>
				<li>Neovim</li>
					<ul>
						<li><a href="https://github.com/junegunn/vim-plug">Vim Plug</a> (dependent on Git and XDG things)</li>
						<li><span className="monospace">ripgrep</span> for Telescope plugin</li>
					</ul>
				<li>Git</li>
				<li>i3 and related things like <span className="monospace">feh/dmenu/gtk3/i3lock-color</span></li>
				<li>Browser</li>
					<ul>
					<li> Firefox + yes, I have a <a href={ff_theme}>custom theme</a>. Here are some plugins I like: </li>
							<ul>
								<li>uBlock Origin</li>
								<li>Firefox Color</li>
								<li>Bypass Paywalls Clean</li>
								<li>HTTPS Everywhere</li>
								<li>Canvas Blocker</li>
								<li>DuckDuckGo Privacy Essentials</li>
								<li>Decentraleyes</li>
								<li>Simple Tab Groups</li>
								<li>Vimium</li>
								<li>Bitwarden</li>
								<li>Proton VPN</li>
							</ul>
						<li> Tor (<span className="monospace">torbrowser-launcher</span>) </li>
						<li> (sometimes) Chromium </li>
						</ul>
				<li>Kitty</li>
				<li>Zathura</li>
					<ul>
						<li>Get support for DjVu: <span className="monospace">zathura-djvu</span></li>
						<li>Get support for EPUB: <span className="monospace">zathura-pdf-mupdf</span> </li>
					</ul>
				<li><span className="monospace">foliate</span> for a <em>good</em> ebook reading experience</li>
				<li><span className="monospace">yay</span> for AUR packages!</li>
				<li>Set up SSH with <span className="monospace">openssh</span> package; OpenVPN is quite nice</li>
				<li>
					Get additional displays working with <span className="monospace">xrandr</span> (as well as <span className="monospace">arandr</span>!), 
					which comes from <span className="monospace">xorg-xrandr</span>. You might need to edit a line or two in your i3 config.
				</li>
				<li>Set brightness with <span className="monospace">brightnessctl</span></li>
				<li>Get audio working with  <span className="monospace">pipewire</span>, <span className="monospace">pipewire-pulse</span>, <span className="monospace">pipewire-alsa</span>, <span className="monospace">pipewire-jack</span> and <span className="monospace">pavucontrol</span></li>
				<li>Polybar </li>
				<li>Use <span className="monospace">cronie</span> to set up cronjobs for updates and cleaning up</li>
				<li><span className="monospace">scrot</span> for screenshots!</li>
				<li>
					<span className="monospace">libqalculate</span> package for <span className="monospace">qalc</span>, a good terminal calculator
				</li>
				<li><span className="monospace">xdg-utils</span> for launching</li>
				<li><span className="monospace">lsd</span> (a colored version of <span className="monospace">ls</span>)</li>
				<li><span className="monospace">xclip</span> for copy/pasting {/*wai/curr_dir and nvim*/} </li>
				<li>ImageMagick for <span className="monospace">icat</span> kitten </li>
				<li><span className="monospace">libinput-gestures</span> from the AUR and <span className="monospace">wmctrl/xdotool</span> </li>
				<li><span className="monospace">pandoc</span> since you don't <em>need</em> to use RStudio to knit. Also, Rmd is nice for notetaking</li>
				<li>
					<span className="monospace">fzf</span>, and use the commands
					<div className="realcode">
						git clone --depth 1 https://github.com/junegunn/fzf.git ~/.fzf <br/>
						~/.fzf/install
					</div>
				</li>
				<li><span className="monospace">arandr</span> and <span className="monospace">autorandr</span> to configure display layouts</li>
				<li><span className="monospace">dunst</span> for prettier notifications </li>
			</ul>
			<h2>Languages!</h2>
			<ul>
				<li>C/C++ (check for <span className="monospace">gcc</span>/<span className="monospace">g++</span>)</li>
				<li>LaTeX (check for <span className="monospace">texlive</span>)</li>
				<li>Python3 and corresponding packages, specifically <span className="monospace">pynvim</span> for Neovim and <span className="monospace">pyright</span> for syntax</li>
				<li>
					NodeJS/ReactJS (check for <span className="monospace">npm</span> and <span className="monospace">nodejs</span>)
				</li>
				<li>RStudio (<span className="monospace">rstudio-desktop-bin</span> from the AUR) + R</li>
			</ul>
			<h2>Todo list (other things I like) </h2>
			<ul>
				<li> 
					Adding yourself to the sudoers file and the <span className="monospace">power</span> group.
					You'll also want to set up a cronjob to run updates for you - this will involve adding a line to the sudoers file, so that these commands won't require a superuser password:
					<div className="realcode">
						krithika faramir= NOPASSWD: /usr/bin/reboot,/usr/bin/poweroff,/usr/bin/pacman -Syu --noconfirm,/usr/bin/pacman -Qdtq --noconfirm,/usr/bin/pacman -Rs --noconfirm -
					</div>
					as well as setting up the cronjob itself using <span className="monospace">crontab -e</span>:
					<div className="realcode">
						00 00 * * 0 /home/krithika/dotfiles/scripts/maintenance.sh
					</div>
					I like mine to run on Sundays at midnight!
				</li>
				<li><Link to="/blog/scroll">Natural scrolling</Link></li>
				<li><Link to="/blog/map">Setting up custom key bindings</Link> </li>
					<ul>
						<li><i>Caps Lock</i> to <i>Esc</i></li>
						<li>
							Multilingual keyboard with dead keys.
							I prefer having <i>AltGr</i> as my dead key (<i>Compose</i> key).
						</li>
					</ul>
				<li>
					<s>
						Set the DejaVu font collection as system default.
						I used to do this with <Link to="/blog/tweaks">GNOME tweaks</Link>
					</s>.
					Nope, NotoSans is way better for multilingual support and emojis.
					For the rest, Source Code Pro works great! I also like Ubuntu Mono Ligaturized.
				</li>
				<li>
					<s>Set default apps! (mime-y stuff). </s>
					The <span className="monospace">mimeapps.list</span> file
					should be symlinked.
				</li>
				<li>
					Symlink all my dotfiles (my hair will grey before I finish this). I do this manually,
					but if you know any easier way to manage this, please let me know!
				</li>
				<li>
					Set up <a href="https://github.com/bulletmark/libinput-gestures">gestures</a>! 
					The config should be symlinked as well.
				</li>
				<li>Zoom</li>
				<li><s>Compositing; I use picom.</s> Compositing's a pain in terms of productivity and performance.</li>
			</ul>
		</div>
	)
}
