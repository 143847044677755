import React from 'react';

export default function Map() {
	return (
		<div>
			<h1>Mapping Keys</h1>

			<h2>The Problem</h2>
			<p>
				The <span className="monospace">Caps Lock</span> key serves mainly as a
				source of annoyance, and receives far too much real estate relative
				to its use. However, the <span className="monospace">Esc</span> key is
				quite useful (I'm primarily a Vim user), but is far too hard to
				reach. Currently, &Eacute;owyn has altered key settings to make
				life a bit easier. The <span className="monospace"> Caps Lock</span> key
				now acts like the <span className="monospace">Esc</span> key.
			</p>
			<h2>Stuff that didn't work</h2>
			<p>
				I first tried this using the command 
			</p>
			<div className="realcode">
				xmodmap -e `clear Lock' -e `keycode 0x42 = Escape'
			</div>
			<p>
				but for some
				reason, it stopped working for one of my TeX files.
				Future me is pretty sure I put this in a file that isn't loaded by default
				on bash, but I can't remember. This will probably still work if you
				put this in the right file; maybe I'll test this later.
			</p>

			<h2>The Solution</h2>
			<ol>
				<li> 
					I installed the <span className="monospace">dconf-editor</span> using
					<div className="realcode"> sudo apt-get install dconf-tools</div>
				</li>
				<li>
					I started the editor, and went to <i>org</i> → <i>gnome</i> → <i>desktop</i> →
					<i>input-sources</i>
				</li>
				<li>
					At the bottom of the page, I saw two square braces with nothing in them.
					These braces are <u>required</u>.
				</li>
				<li>
					Within these braces, I added <div className="realcode"> 'caps:escape'</div> to
					add the <i>Caps Lock</i> key as another <i>Esc</i>. The command you want
					to use needs to be enclosed in single quotes. If you want multiple options,
					they need to be comma separated. A full list of commands can be found by
					typing <div className="realcode">man 7 xkeyboard-config</div>
				</li>
				<li> Exit out, and you're good to go! </li>
			</ol>
			<h2>With Cinnamon</h2>
			<p>
				On Cinnamon, which <s>is what I'm currently using</s> I used to use, the solution was a bit different,
				and I liked it a lot less. I thought the process would be similar, using some form of
				<span className="monospace">gnome-tweaks</span> since Cinnamon is GNOME-based, but it was a fair bit messier.
			</p>
			<p>
				Edit the file <span className="monospace">/etc/default/keyboard</span> as
				superuser. There'll be a line
				with <span className="monospace">XKBOPTIONS="blah"</span>,
				where <span className="monospace">blah</span> represents
				your current settings, most likely nothing. Just put the
				string <span className="monospace">caps:escape</span> inside of
				those quotes and restart. That's it!
			</p>
			<h2>Update on XKB stuff</h2>
			<p>
				Yeehaw. On i3, things, uh, work a bit differently, since the normal
				tools I use are not easily available to me.
				Here's the entirety of the previous file:
			</p>

			<div className="realcode">
				XKBMODEL="pc105" <br />
				XKBLAYOUT="us" <br />
				XKBVARIANT="" <br />
				XKBOPTIONS="compose:ralt,caps:escape" <br />
				<br />
				BACKSPACE="guess" <br />
			</div>
			<h2>Update #2, with Arch Linux</h2>
			<p>
				Right, so here's an even easier solution! Just add the following line
				to your config:
			</p>
			<div className="realcode">
				setxkbmap -option compose:ralt,caps:escape	
			</div>
			<p>
				The only way I could get this to work is by adding it to my i3 config;
				I preceded it with <span className="monospace">exec_always --no-startup-id</span>.
				This solution's a lot better since you don't have to edit those sensitive
				files yourself.
			</p>
			<h2>Arrow Keys</h2>
			<p>
				I really felt the need to add this at a later date. Why, you might ask?
				Sheer fear (which is also what I felt writing half the content on this website).
			</p>
			<p>
				Like a lot of folks, when I'm stressed, I like
				to change things, often a bit impulsively. This could be a haircut, reflashing my computer
				with a new distro, clean up/decorate my work space, etc. And these actions all have their
				own consequences.
			</p>
			<p>
				This time, I disabled my arrow keys, using <span className="monospace">xmodmap</span>, which I
				talked about earlier on this page.
				I guess I thought I needed to learn Vim and Emacs (since the default for terminals is Emacs) keybindings.
				Based on what
				<a href="https://superuser.com/questions/775785/how-to-disable-a-keyboard-key-in-linux-ubuntu"> another
				user said on StackExchange</a>,
				I ran the command
			</p>
			<div className="realcode">
				xmodmap -e 'keycode 116='; xmodmap -e 'keycode 113='; xmodmap -e 'keycode 114='
			</div>
			<p>
				Initially, I panicked, since I couldn't reverse it, and there are unanswered
				comments below the accepted answer
				(this entry is dated 10/18/20) asking how to undo the action. However, somehow, it, um, undid itself?
				I'm not entirely sure what's up with that. But we'll see, I guess.
			</p>
		</div>
	)
}
