import React from 'react';
import { Link } from 'react-router-dom';

export default function Tweaks() {
	return (
		<div>
			<h1>Accent Marks in Ubuntu (GNOME)</h1>

			<h2>The Problem</h2>
			<p>
				I needed to be able to type diacritics with my keyboard on &Eacute;owyn.
				I installed the "English (intl., with AltGr dead keys)" keyboard, but
				when I typed in the correct key bindings, such as <i>AltGr</i> + <i>a</i>, the character
				"&aacute;" was not produced.
			</p>

			<h2>The Solution</h2>
			<p>
				The issue was that the <em>Compose</em> key was not set. Hence,
				I had to set the <em>Compose</em> key to
				the <em>AltGr</em> key.
			</p>
			<p>
				I did this by installing the <span className="monospace"> gnome-tweaks</span> utility with
				<span className="monospace">sudo apt install gnome-tweak-tool</span> and ran it with
				<span className="monospace"> gnome-tweaks</span>. In Tweaks,
				I went to <i>Keyboard & Mouse</i> → <i>Compose Key</i>. 
				The default is set to <span className="monospace">off</span>, but you can toggle
				that switch and pick the key you want. I went
				with <em>AltGr</em>, since that was what the keyboard I downloaded said to use.
			</p>
			<h2>Additional Fun Stuff</h2>
			<p>
				You can do a lot of other cool things with Tweaks - you can customize how pretty much
				everything looks. You can change the fonts the OS uses, what format the time
				should be printed in, pick which apps should be launched at boot up, and so much more
				(and I realized I sound like an ad when I say this).
			</p>

			<h2> Future Note</h2>
			<p>
				Future note: this works quite well on GNOME, but it doesn't work so well
				on GNOME-based desktop environments like Cinnamon.
				Check out my entry on <Link to="/blog/map">mapping keys</Link> for more info.
			</p>
		</div>
	)
}
