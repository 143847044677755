import React from 'react';
import { Link } from 'react-router-dom';

export default function MacPreferences() {
	return (
		<div>
			<h1>My Mac Setup</h1>
			<p>
				I really wanted my Mac setup to look as similar as possible to my <Link to="/blog/preferences">Linux setup</Link>.
				This became all the more important once I started using a Mac for work.
				Mac already comes with a lot of commonly used packages installed, like Git and Python, so that's why this list is a lot shorter than
				my Linux setup list.
				I'll add more things as I think of them!

			</p>
			<h2>Things to install</h2>
			<ul>
				<li><a href="https://brew.sh/">Homebrew</a></li>
				<li>Neovim</li>
					<ul>
						<li><a href="https://github.com/junegunn/vim-plug">Vim Plug</a></li>
					</ul>
				<li>Kitty</li>
				<li>
					<span className="monospace">coreutils</span> so that I can use the GNU version
					of <span className="monospace">ls</span> rather than the BSD version
				</li>
				<li><span className="monospace">lsd</span> (a colored version of <span className="monospace">ls</span>)</li>
				<li>
					<span className="monospace">libqalculate</span> package for <span className="monospace">qalc</span>, a good terminal calculator
				</li>
				<li>
					A browser!
					<ul>

						<li> Firefox, with all the fixings mentioned in my Linux setup </li>
						<li>Chrome for my work laptop. Here are some extensions I like:</li>
							<ul>
								<li>uBlock Origin</li>
								<li>Canvas Blocker</li>
								<li>DuckDuckGo Privacy Essentials</li>
								<li>Vimium</li>
								<li>Group Tabs By Domain</li>
								<li>
									<a href="https://chrome.google.com/webstore/detail/under-the-leaf/ighhdikapddjihgaphbnbcgipafcamjk?hl=en-US">Under
									The Leaf</a> theme, by Marisol Ortega
								</li>
							</ul>
					</ul>
				</li>
			</ul>
			<h2>Todo list (other things I like) </h2>
			<ul>
				<li>
					Get your <span className="monospace">.zshrc</span> and aliases over!
					Same for a <span className="monospace">.vimrc</span>, any Vim colorschemes
					(these go in <span className="monospace">~/.vim/colors/</span> ), and 
					like 3 lines in <span className="monospace">~/.config/nvim/init.vim</span>.
				</li>
				<li>
					Speaking of a <span className="monospace">.zshrc</span>, you'll need to add <span className="monospace">alias ls='gls'</span>
					at the top of your aliases file, before the other <span className="monospace">ls</span> aliases in order to get
					the <span className="monospace">LS_COLORS</span> variable working.
				</li>
				<li>
					In the <span className="monospace">.zshrc</span>, change the value
					of <span className="monospace">PS1</span> to <span className="monospace">%F&#123;green&#125;>%f </span> (this is because I migrate
					from Bash to Zsh)
				</li>
				<li>
					Install the Ubuntu Mono Ligaturized fonts. Within the Font Manager, make sure all the font styles are activated.
					On Macs, for some reason, 12pt font is a little too small for me, so I like making it 14pt (changed in the kitty config file).
				</li>
				<li>I like to set my desktop color to a relaxing dark green, <span className="monospace">#002E20</span></li>
				<li>
					Just to have it somewhere, here's my Slack theme:
					<div className="realcode">#1F2E29,#1A2B23,#79F79D,#1A1D21,#1A2B23,#00FFB7,#4EE077,#1C913D,#1A2B23,#4EE077</div>
				</li>
				<li>
					To enable Git autocomplete in Zsh, the following line to your <span className="monospace">.zshrc</span>
					<div className="realcode">
						autoload -Uz compinit && compinit
					</div>
				</li>

			</ul>
		</div>
	)
}
