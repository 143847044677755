import React from 'react';
import { Link } from 'react-router-dom';

export default function Teaching () {
	return (
		<div>
			<h1>ESL Lecture Notes</h1>
			<p>
				I'm teaching an introductory ESL course
				at <a href="https://elbuen.org/">El Buen Samaritano</a> during the fall 2024 session!
				<br/>
				<br/>
				Here are a set of lecture notes covering both
				things we've talked about in class as well as some additional examples.
				I'll be updating this page as the semester progresses.
				Hopefully this helps!
			</p>
			<ul>
				<li><Link to="/teaching/week01">Week 1 (week of 9/2)</Link></li>
				<li><Link to="/teaching/week02">Week 2 (week of 9/9)</Link></li>
				<li><Link to="/teaching/week03">Week 3 (week of 9/16)</Link></li>
				<li><Link to="/teaching/week04">Week 4 (week of 9/23)</Link></li>
				<li><Link to="/teaching/week05">Week 5 (week of 9/30)</Link></li>
				<li><Link to="/teaching/week06">Week 6 (week of 10/7)</Link></li>
				<li><Link to="/teaching/week07">Week 7 (week of 10/14)</Link></li>
				<li><Link to="/teaching/week08">Week 8 (week of 10/21)</Link></li>
				<li>Week 9 (week of 10/28): No class; Happy Halloween!</li>
				<li><Link to="/teaching/week09">Week 10 (week of 11/4)</Link></li>
				<li><Link to="/teaching/week11">Week 11 (week of 11/11)</Link></li>
				<li><Link to="/teaching/week12">Week 12 (week of 11/18)</Link></li>
				<li>Week 13 (week of 11/25): No class; Happy Thanksgiving!</li>
				<li><Link to="/teaching/week13">Week 14 (week of 12/2)</Link></li>
				<li><Link to="/teaching/week14">Week 15 (week of 12/9)</Link></li>
			</ul>

		</div>
	)
}
