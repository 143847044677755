import React from 'react';
import { Link } from 'react-router-dom';

export default function Dellxps() {
	return (
		<div>
			<h1>Boot Up Frustrations in Dell XPS (Goodbye, &Eacute;owyn)</h1>

			<h3>Goodbye </h3>
			<p>
				The last few months have been rough on &Eacute;owyn (Dell XPS 15 9570).
				I've had her since June 2019, right up till February 2022.  It hasn't even
				been 3 years. That's the computer where I first messed around with my
				setups - I customized and debugged them until I was happy with
				where they were. I got to try out different window managers and Linux
				distros, and this was the first computer that I took apart to replace
				parts.
			</p>

			<h3>What happened?</h3>
			<p>
				Rather than divide this section into "what did I try that
				didn't work" and a solutions section, I'm choosing to
				write this more as a story.
			</p>
			<h4>December 11, 2021</h4>
			<p>
				After keeping my laptop running for about two weeks
				(not doing particularly CPU-intensive tasks, just
				editing, PDF-viewing, and music), on December 11, 2021, 
				I decided to reboot it.
				It wouldn't wake up. 
			</p>
			<p>
				I tried some combination of 
			</p>
			<ul>
				<li> pressing the power button briefly, then waiting </li>
				<li> holding the power button for different intervals (about 10s, 20s, 30s) </li>
				<li> plugging in and unplugging the AC charger </li>
				<li> removing USBs and HDMI cable </li>
				<li> begging, pleading, cajoling </li>
			</ul>
			<p>
				Most of the time, the keyboard would light up, but I'd get nothing more. A
				couple times, I got the Dell logo, but it'd immediately turn off, not even
				showing the boot messages. I can't remember which combination of things got
				the logo to appear. I'd often get an amber blinking pattern of 3 amber
				flashes followed by 5 white flashes, <a
				href="https://www.dell.com/support/kbdoc/en-au/000141206/a-reference-guide-to-the-xps-notebook-diagnostic-indicators#2014_Present">indicating
				a SBIOS corruption</a>. I didn't have another computer with which I could
				burn a BIOS, but after a bit of waiting and hitting the power button, the
				issue somehow resolved itself. I was warned to update my BIOS soon, but I
				was too lazy to do that.
			</p>

			<h4>January 23, 2022</h4>
			<p>
				Between Dec 11 and Jan 23, reboots would take a few attempts.
				I tried to keep these to a minimum, since I was scared that each
				time would be the last.
				Sometimes I'd get nothing, but typically within 5-10 minutes of
				trying, I'd be able to boot up and log in, and everything was fine-ish.
				{/* i went home for winter break -> not really a neeeeed */}
				On Jan 23, after almost an hour of trying, I couldn't get it to boot. The
				keyboard would occasionally light up, but I wasn't even able to reach the Dell logo.
				I maybe got the Dell logo twice, but I wasn't able to log in at all.
				Finally, disconnecting the battery let me log in.  This time, I got the 2, 1
				blinking pattern that indicates CPU failure.  However, I'd also
				occasionally get the 3, 5 pattern, which indicated I had BIOS issues.
			</p>
			<p>
				I wasn't able to re-flash the BIOS immediately, since that needed both
				battery and AC power.  I swapped the battery and reflashed the BIOS.  It
				would let me log in provided I wasn't connected to AC power.  I tried
				disabling the adapter power warning, but that didn't seem to make much of
				an impact. I was able to boot after shutdowns and reboots, and everything
				seemed to be okay for a while.

			</p>

			<h4>February 20, 2022</h4>
			<p>
				The WiFi stopped working. I can't remember what error
				message <span className="monospace">iwd</span> gave me, but it was one I'd
				never seen before. <span className="monospace">iwctl</span> wouldn't
				even show me its menu.
				I tried running <span className="monospace">sudo systemctl restart iwd</span>, which is what 
				I typically do when I run into any sort of issue with <span className="monospace">iwd</span>.
				Restarting <span className="monospace">iwd</span> normally takes around 5 seconds, but this
				time it froze up. I <em>Ctrl + C</em>-d that, and tried to
				restart. That also froze up, so I hit the power button instead. After it powered off
				(this took a bit, since it had to kill <span className="monospace">iwd</span> properly and stuff),
				it wouldn't wake up.
			</p>
			<p>
				Based on the 2, 1 blinking pattern, it looks to be a CPU failure or a board
				corruption, both of which are difficult to fix. I suspected
				this had something to do with the hardware components related to WiFi modules,
				since the <span className="monospace">iwd</span> error was one I hadn't seen before.
				I haven't gotten a successful boot up in days. 
				Either way, I needed a more reliable computer
				(Zoom classNamees from a phone are a pain), so I got Mithrandir, a MacBook Pro.
				I will probably have a write up about that set up at some point. <s>I might 
				wind up making that a dual boot with Arch; I miss Arch. Alternatively,
				I might keep this is as a reliable back up, and get another computer
				for my main stuff. </s> I wound up choosing the latter option; that computer is
				now <Link to="/blog/arch">Faramir</Link>.
			</p>

			<h3>Current status</h3>
			<h4>March 3, 2022</h4>
			<p>
				It was a bit too hard to say goodbye, so I went ahead and tried to boot again.
				I was finally able to reach the Dell page, but I wasn't able to boot up properly.
				It would give me an error about not being able to find the root partition, and
				dropped me into an emergency shell (<span className="monospace">rootfs</span>).
			</p>
			<p>
				There was no <span className="monospace">sda</span> or disk related device in
				the <span className="monospace">/dev</span> directory, so it wasn't detecting the hard drive.
				This is similar to what happened in February, where it wasn't recognizing peripherals.
				I tried rebooting normally a few times, which did nothing (the exit code was 0, and
				I was able to run commands in the shell perfectly normally).
				<span className="monospace"> reboot -f</span>, however, worked. This was oddly reminiscent
				of the previous issue I had with rebooting, where it wasn't correctly processing/executing
				the command. On rebooting, it took me right back into the
				emergency shell. I tried taking a look at the BIOS, but there wasn't anything there of use.
				I haven't even gotten the Dell logo since.
			</p>
			<p>
				If it really is a chip failure, there's not much I can do at this stage.
				I'm sorry about every time I complained about you; you taught me so much.
				I really miss you.
				Goodbye; I loved you. {/* cries */}
			</p>
		</div>
	)
}
